import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FooterBox from '../components/share/footerBox'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import Quote from '../components/share/quote'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "share/plugged.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wire: file(relativePath: { eq: "marketplace/wire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vincent: file(relativePath: { eq: "brandAgentIcon/vincent_lecamus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceOrderItems: file(
      relativePath: { eq: "marketplace/services_order_items.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    crmApi: file(relativePath: { eq: "marketplace/crm-api.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    serviceProvider: file(
      relativePath: { eq: "marketplace/services_providers.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    puzzle: file(relativePath: { eq: "marketplace/puzzle.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    dougHutchinson: file(
      relativePath: { eq: "brandAgentIcon/doug_hutchinson.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class MarketplacePage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                opacity="0.6"
                style={{ height: '600px' }}
                skew
              >
                <ContentWrapper>
                  <FontAwesomeIcon
                    icon={['fal', 'shopping-basket']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />
                  <BannerH6 style={{ marginBottom: 20, textAlign: 'start' }}>
                    Built for the Suppliers too --{' '}
                    <strong>We give them the tools</strong>
                  </BannerH6>
                  <HeaderTitle>
                    All your suppliers plugged in together. In one spot.
                  </HeaderTitle>

                  <HeaderBody>
                    It doesn’t take 800 logins and 800 different systems to
                    completely market yourself and your property anymore. Our
                    supplier agnostic system provides the tools to do it all in
                    one place.
                  </HeaderBody>
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <IntroRow
                  title="Proptech as an industry is taking off into the stratosphere."
                  descWidth="80%"
                  content={
                    <Fragment>
                      <Paragraph>
                        There’s been over <strong>$12 Billion</strong> invested
                        into exciting new property niche technology in 2018.
                        That’s grown from
                        <strong>
                          $186 Million a year in 2011. That’s a 64x increase in
                          spending.
                        </strong>
                      </Paragraph>
                      <Paragraph>
                        The industry is always announcing{' '}
                        <strong>game-changers</strong> and the
                        <strong>‘Uber’</strong> of a specific single point of
                        contact Agents currently maintain, but most systems fail
                        to give complete customer care but only handle one small
                        area.
                      </Paragraph>

                      <Paragraph>
                        Instead of trying to be the best at everything, we’re
                        letting experts carve out and service their niche, and
                        providing a place for them all to unite and become
                        accessible to the modern digital agent.
                      </Paragraph>
                    </Fragment>
                  }
                />

                <Quote
                  agentImage={data.vincent.childImageSharp.fluid}
                  agentName="Vincent Lecamus"
                  desc="“Proptech is one small part of a wider digital transformation in the property industry. It considers both the technological and mentality change of the real estate industry, and its consumers to our attitudes, movements and transactions involving both buildings and cities”"
                />
              </ContentWrapper>

              <ImageBackground
                height="700px"
                opacity="0.7"
                background={data.wire.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="50%"
                    image={data.serviceOrderItems.childImageSharp.fluid}
                    title={
                      <strong>
                        You’ve probably got too many subscriptions, and too many
                        logins to different platforms already.
                      </strong>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          Enough is enough. Every different marketing avenue
                          shouldn’t need a new login, with a new system to learn
                          and a new password to remember. It’s hard enough
                          getting a team of agents or managers all in the same
                          room once to learn new software, let alone ten times a
                          year.
                        </Paragraph>
                        <Paragraph>
                          By bringing all your services under the one umbrella,
                          and that umbrella being so intuitive that anyone can
                          use it with minimal training, you reduce friction and
                          the risk of information being lost when changing
                          staff..
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.crmApi.childImageSharp.fluid}
                  title="It’s your data, you should be able to take it with you, and send it where you like."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Taking advantage of industry standards and unique
                          offerings alike, we play ball with the entire
                          industries potential technology stack via API.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Creating and editing campaigns again, and again, and
                        again for every piece of the marketing puzzle is a
                        nightmare. We capture campaign data, and seamlessly
                        release it back out to marketing providers, so you only
                        enter data once, finally.
                      </Paragraph>
                      <Paragraph>
                        Built around your existing CRM, and your existing
                        suppliers we’re an easy to learn conduit that focuses on
                        making your multi-faceted marketing approach’s cohesive,
                        and improving communication between every platform.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  reverse
                  image={data.serviceProvider.childImageSharp.fluid}
                  title={
                    <Fragment>
                      Finally, all your suppliers,{' '}
                      <strong>plugged in together.</strong>
                    </Fragment>
                  }
                  content={
                    <Fragment>
                      <Paragraph>
                        It doesn’t have to take{' '}
                        <strong>28 different logins</strong> to{' '}
                        <strong>
                          28 different systems you shouldn’t even need 3
                        </strong>
                        . It’s not your job to know the contact details or
                        correct technical specifications for an artwork order.
                        Not anymore. We are currently plugged into over 400
                        major Real Estate suppliers, and the national newspaper
                        network with more and more becoming available daily.
                      </Paragraph>
                      <Paragraph>
                        If you’ve got your own suppliers and they aren’t already
                        available, let us know and{' '}
                        <strong>we’ll get them set up ASAP</strong> so you can
                        experience the benefits of getting all your marketing
                        under the one login.
                      </Paragraph>

                      <Paragraph>
                        We offer connectivity from simple email order
                        notifications, to complete system integrations that feed
                        up to the minute information back to you from your
                        providers creating better transparency and easier to
                        manage campaigns.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <Quote
                  desc="“Realhub removed prior inefficiencies across the group that relies on a multitude of suppliers contained. We’re eliminating waste through the adoption of Realhub. And that’s what's going to drive value in our business.”"
                  agentImage={data.dougHutchinson.childImageSharp.fluid}
                  agencyName="BarryPlant"
                  agentName="Doug Hutchinson"
                  title="Operations Manager"
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  descWidth="50%"
                  image={data.puzzle.childImageSharp.fluid}
                  title={<strong>Open connectivity is the future</strong>}
                  link="https://api-docs.realhub.com.au/"
                  linkText="Tech Savvy? Check out the API Docs"
                  content={
                    <Fragment>
                      <Paragraph>
                        The future of real estate, and it’s technology partners
                        isn’t one system to rule them all, it’s a million small
                        pieces of technology that you can build a complete
                        cohesive picture from, like a giant puzzle, Realhub
                        makes all the pieces fit together.
                      </Paragraph>
                      <Paragraph>
                        We are built on a platform used by leaders of the
                        technology industry like Facebook, Netflix, Instagram,
                        Dropbox and even Australia’s largest technology start-up
                        Atlassian, which now brings all those huge benefits over
                        to you.
                      </Paragraph>
                      <Paragraph>
                        Our open API allows new and existing platforms to build
                        exciting new products and become partners, they can then
                        access your agency as plug-ins to fit your specific
                        needs.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <IntroRow
                  title={<strong> BYO platforms, let us connect it.</strong>}
                  content={
                    <Fragment>
                      <Paragraph>
                        We want to stand as a model for high-quality business
                        relationships and integrations. Real estate marketing
                        has been kept under cloak and dagger too long. An open
                        language we all share benefits everybody from the agent
                        to the supplier.
                      </Paragraph>
                      <Paragraph>
                        We are open to the development of integrated
                        partnerships from the web to mobile, always with the
                        best interests of the customers in mind. We’re happy for
                        teams to build their technology, off the back of our
                        platform, with the agencies data and marketing..
                      </Paragraph>
                      <Paragraph>
                        Together we can stand on the shoulders of giants and
                        build a better, disruption-free future for Real Estate.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <Quote
                  agentImage={data.vincent.childImageSharp.fluid}
                  agentName="Vincent Lecamus"
                  desc="“Technology is what helps keep the real estate industry on its toes. Increasingly, new actors are flooding the market, offering tools and solutions for real estate professionals, or harbouring the ambition to become the Uber of real estate.”"
                />
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Your <strong>tech-connected</strong> and{' '}
                  <strong>communication embracing,</strong> platform with all
                  the other products it provides your business, are minutes
                  away. <strong>Get ready for the change.</strong>
                </BannerH4>

                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default MarketplacePage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
